export const roles = [
    {
        id: 1,
        name: "Administrador",
        description: "Administrador del sistema",
    },
    {
        id: 2,
        name: "Usuario final",
        description: "Usuario final del sistema",
    },
]